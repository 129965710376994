


























































































































import Vue from 'vue';

const Support = Vue.extend({
  name: 'support',
  methods: {
    submitHandler(event: Event): boolean {
      event.preventDefault();
      return false;
    },
  },
});

export default Support;
